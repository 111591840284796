import React from "react"
import { PublicClientApplication } from "@azure/msal-browser"
import { BrowserRouter } from "react-router-dom"
import { msalConfig } from "./authConfig"
import { Toaster } from "react-hot-toast"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import ReactDOM from "react-dom/client"
import App from "./App"

// ===== Redux Provider =====
import { Provider } from "react-redux"

// ===== MSAL Provider =====
import { MsalProvider } from "@azure/msal-react"

// ===== Redux Store =====
import { store } from "./store/store"

export const msalInstance = new PublicClientApplication(msalConfig)
await msalInstance.initialize()

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
        <Toaster position="top-right" reverseOrder={false} gutter={8} />
      </MsalProvider>
    </Provider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()