import React, { useEffect, useMemo, useRef, useState } from "react"
import { createUpdateOrder, getAdminLocation, getAllCompanyNameQuery, getCateringProduct, getDepartment, getMeetingByRoom, getMeetingRoomByLocation, searchColleague } from "../../services/ApiServices"
import { changeAllLocationValue } from "../../store/slices/APIResponseSlice"
import { useDispatch, useSelector } from "react-redux"
import { InputMask } from '@react-input/mask';
import { emailRegex } from "../../const"
import { Tooltip } from "react-tooltip"
import { toast } from "react-hot-toast"
import CreatableSelect from "react-select/creatable"
import ReactDatePicker from "react-datepicker"
import Loader from "../Loader/Loader"
import moment from "moment/moment"
import Select from "react-select"
import "react-tooltip/dist/react-tooltip.css"
import "./CreateOrder.css"

// ==== Imported Image ====
import iconAdd from "../../assets/img/icon-add.svg"
import iconDelete from "../../assets/img/icon-trash.svg"
import iconArrow from "../../assets/img/icon-arrow-down.svg"
import ReactSelect from "react-select";

export default function CreateOrder() {
  const allCanteenUser = useSelector((state) => state?.APIResponse?.AllCanteenUsers)
  const allLocations = useSelector((state) => state?.APIResponse?.AllLocations)

  const [isLoading, setIsLoading] = useState(false)
  const [isSaveOrderLoading, setIsSaveOrderLoading] = useState(false)
  // const [orderBySearchStatus, setOrderBySearchStatus] = useState(false)
  const [userSearch, setUserSearch] = useState("")
  const [colleaguesList, setColleaguesList] = useState([])
  const [orderByName, setOrderByName] = useState("")
  const [orderByEmail, setOrderByEmail] = useState("")
  const [allMeetingRoom, setAllMeetingRoom] = useState([])
  const [allMeetingsTime, setAllMeetingsTime] = useState([])
  const [allCateringProduct, setAllCateringProduct] = useState([])
  const [allDepartments, setAllDepartments] = useState([])
  const [allCompanyResponse, setAllCompanyResponse] = useState([])

  const [orderDetails, setOrderDetails] = useState([])

  const [inputLocation, setInputLocation] = useState({ value: 0, label: "Select Location" })
  const [inputDate, setInputDate] = useState(moment().format("YYYY-MM-DD"))
  const [inputMeetingRoom, setInputMeetingRoom] = useState(0)
  const [inputMeetingTime, setInputMeetingTime] = useState({ value: "", label: "Select Meeting Room Time" })
  const [inputDepartment, setInputDepartment] = useState({})
  const [inputCanteenUser, setInputCanteenUser] = useState(0)
  const [inputDeliveryMethod, setInputDeliveryMethod] = useState("Delivery")
  const [inputInternal, setInputInternal] = useState(0)
  const [inputExternal, setInputExternal] = useState(0)
  const [inputSharedComment, setInputSharedComment] = useState("")
  const [inputInternalComment, setInputInternalComment] = useState("")
  const [inputOrderStatus, setInputOrderStatus] = useState(0)
  const [invoiceInternal, setInvoiceInternal] = useState(true)
  const [invoiceExternalDetails, setInvoiceExternalDetails] = useState({
    CompanyName: "",
    Address: "",
    Zip: "",
    CVR: "",
    Att: "",
    Mail: ""
  })
  const [compnayQueryString, setCompnayQueryString] = useState("")
  const [inputDiscount, setInputDiscount] = useState("")
  const [inputAmount, setInputAmount] = useState("")
  const [disPercent, setDisPercent] = useState("")
  const [discountTotalDisplay, setDiscountTotalDisplay] = useState(false)
  const [discountAmountDisplay, setDiscountAmountDisplay] = useState(false)

  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const [error, setError] = useState({ Status: false, Indexs: [] })

  const locationRef = useRef()
  const meetingRef = useRef()
  const meetingTimeRef = useRef()
  const costCenterRef = useRef()
  const deliveryRef = useRef()
  const wrapperRef = useRef(null)

  // const orderBySearch = useRef()

  const dispatch = useDispatch()

  // Company Query Search
  useEffect(() => {
    let subscribed = true

    const timeout = setTimeout(() => {

      if (compnayQueryString?.trim()) {
        getAllCompanyNameQuery(compnayQueryString).then((response) => {
          if (subscribed) {
            response?.length > 0 && setAllCompanyResponse(response)
          }
        }).catch((error) => {
          if (subscribed) {
            console.error(error)
          }
        }).finally(() => {
          if (subscribed) {
          }
        })
      }
    }, 1000);

    return () => {
      subscribed = false
      clearTimeout(timeout)
    }
  }, [compnayQueryString])

  const selectedMeeting = useMemo(() => {
    if (inputMeetingTime?.value) {
      return allMeetingsTime?.find((d) => d?.ExchangeICalUId === inputMeetingTime?.value)
    } else {
      return {}
    }
  }, [allMeetingsTime, inputMeetingTime?.value])

  const meetingLocation = useMemo(() => {
    if (allLocations?.length > 0 && inputLocation?.value) {
      return allLocations?.find((d) => d?.Id === inputLocation?.value)
    } else {
      return {}
    }
  }, [allLocations, inputLocation?.value])

  // Total Price
  const calPrice = useMemo(() => {
    let price = 0
    let filteredData = orderDetails?.filter((d) => d?.ProductName && d?.NumberOfItems && d?.NumberOfItems && d?.Price)
    if (filteredData?.length > 0) {
      filteredData?.forEach((d) => {
        price += d?.NumberOfItems * d?.Price
      })
    }
    return price
  }, [orderDetails])

  // Discounted Price
  const discountTotal = useMemo(() => {
    let discountAmount = calPrice * disPercent / 100
    let finalAmount = calPrice - discountAmount

    return { amount: finalAmount.toFixed(2), discount: discountAmount === 0 ? "" : (discountAmount)?.toFixed(2) }
  }, [calPrice, disPercent])

  // Discounted Amount
  const discountAmount = useMemo(() => {
    let discountTotal = calPrice - inputAmount
    let percent = Math.round(inputAmount * 100 / calPrice)
    setInputDiscount(
      percent === 0
        ? ""
        : percent
    )

    return { amount: discountTotal?.toFixed(2) }
  }, [calPrice, inputAmount])

  useEffect(() => {
    setInputAmount(discountTotal.discount === 0 ? "" : discountTotal?.discount)
  }, [discountTotal])

  const colleagueOptions = colleaguesList?.map((user) => ({
    label: `${user.Name} (${user.Email})`,
    value: user
  }));

 
  const handleChange = (selectedOption) => {
    console.log('selectedOption', selectedOption)
    if (selectedOption) {
      const user = selectedOption.value;
      setOrderByName(user?.Name);
      setOrderByEmail(user?.Email);
      setUserSearch("");
      setShowValidationMessage(false);
      // setColleaguesList([]);
      // setOrderBySearchStatus(false);
    } else {
      setOrderByName("");
      setOrderByEmail("");
    }
  };


  const formatOptionLabel = ({ value }) => (
    <div className="item-card item-card-hover">
      <div>
        <b className="txt-name mb-0">{value?.Name}</b>
      </div>
      <div>
        <p className="txt-sm mb-0">{value?.Email}</p>
      </div>
    </div>
  );

  const handleWrapperClick = () => {
    if (!showValidationMessage && userSearch.length < 3) {
      setShowValidationMessage(true);
    }
  };


  const handleInputChange = (inputValue) => {
    setUserSearch(inputValue);
    // setShowValidationMessage(false);
  };


  const addRow = () => {
    setOrderDetails([
      ...orderDetails,
      {
        Id: 0,
        ProductName: "",
        NumberOfItems: "",
        Price: "",
        DeliveryHour: moment(selectedMeeting?.Start).format("HH"),
        DeliveryMinute: moment(selectedMeeting?.Start).format("mm")
      }
    ])
  }

  const setValue = (data) => {
    let arr = []
    if (data && data?.ProductOrders && data?.ProductOrders?.length > 0) {
      arr = [
        ...arr,
        ...data?.ProductOrders?.map((d) => ({
          Id: d?.Product?.Id,
          ProductName: d?.Product?.Name,
          NumberOfItems: d?.NumberOfItems,
          Price: d?.Product?.Price,
          DeliveryHour: typeof d?.DeliveryHour === "number" ? moment(d?.DeliveryHour, "HH").format("HH") : moment(selectedMeeting?.Start).format("HH"),
          DeliveryMinute: typeof d?.DeliveryMinute === "number" ? moment(d?.DeliveryMinute, "mm").format("mm") : moment(selectedMeeting?.Start).format("mm")
        }))]
    }
    if (data && data?.ProductOtherOrders && data?.ProductOtherOrders?.length > 0) {
      arr = [
        ...arr,
        ...data?.ProductOtherOrders?.map((d) => ({
          Id: d?.Id ?? 0,
          ProductName: d?.Name,
          NumberOfItems: d?.NumberOfItems,
          Price: d?.Price,
          DeliveryHour: typeof d?.DeliveryHour === "number" ? moment(d?.DeliveryHour, "HH").format("HH") : moment(selectedMeeting?.Start).format("HH"),
          DeliveryMinute: typeof d?.DeliveryMinute === "number" ? moment(d?.DeliveryMinute, "mm").format("mm") : moment(selectedMeeting?.Start).format("mm")
        }))]
    }
    setOrderDetails(arr.sort((a, b) => Number(`${a?.DeliveryHour}${a?.DeliveryMinute}`) - Number(`${b?.DeliveryHour}${b?.DeliveryMinute}`)))
    setInputDeliveryMethod(data && data?.DeliveryMethod === "Pickup" ? data?.DeliveryMethod : "Delivery")
    setInputCanteenUser(data && data?.CanteenUsers?.length > 0 ? data?.CanteenUsers[0]?.Id : 0)
    const defaultDepartment = allDepartments?.filter((department) => department?.Active)?.find((department) => department?.IsDefault)
    setInputDepartment(
      data && data?.Department?.Id
        ? { value: data?.Department?.Id, label: data?.Department?.Name }
        : {
          value: defaultDepartment?.Id ?? 0,
          label: defaultDepartment?.Name ?? "Select Costcenter"
        }
    )
    setInputInternal(data?.NumberOfPeople ? data?.NumberOfPeople : 0)
    setInputExternal(data?.ExternalAttendeesPeoples ? data?.ExternalAttendeesPeoples : 0)
    setInputSharedComment(data?.Comment ? data?.Comment : "")
    setInputInternalComment(data && data?.InternalComment ? data.InternalComment : "")
    setOrderByName(data && data?.OrderedByName ? data?.OrderedByName : "")
    setOrderByEmail(data && data?.OrderedByEmail ? data?.OrderedByEmail : "")
    setInputOrderStatus(data && data?.OrderStatus ? data?.OrderStatus?.Id : 0)
    setInvoiceInternal(data && Object.keys(data).length > 0 ? data?.InvoiceInternal ? data?.InvoiceInternal : false : true)
    setInvoiceExternalDetails(data && data?.InvoiceExternalDetails ? data?.InvoiceExternalDetails : {
      CompanyName: "",
      Address: "",
      Zip: "",
      CVR: "",
      Att: "",
      Mail: ""
    })
    setInputDiscount(data && Object.keys(data).length > 0 && data?.Discount === 0 ? "" : data?.Discount)
    setDisPercent(data && typeof data.Discount !== "undefined" && data.Discount === 0 ? "" : data?.Discount ?? "")
  }

  const removeRow = (order) => {
    setOrderDetails((prev) => prev?.filter((d) => d !== order))
  }

  const handleProductChange = (Id, i, event) => {
    let arr = [...orderDetails]
    let product = allCateringProduct?.length > 0
      ? allCateringProduct?.find((product) => product?.Id === Id)
      : {}
    if (event?.value && event?.label && event?.value === event?.label) {
      // Other Product Case
      arr[i].ProductName = event?.value
      arr[i].Id = event?.value
      setOrderDetails(arr)
    } else {
      arr[i].ProductName = product?.Name ?? ""
      arr[i].Id = product?.Id ?? 0
      setOrderDetails(arr)
    }
    updateOrderPrice()
  }

  const handleQtyChange = (qty, i) => {
    let arr = [...orderDetails]
    arr[i].NumberOfItems = qty
    setOrderDetails(arr)
  }

  // ===============================================================================================
  const handleDeliveryTimeChange = (time, index) => {
    let arr = [...orderDetails]

    setError((prev) => ({ ...prev, Indexs: prev?.Indexs?.filter((i) => i !== index) }))

    // /^(?:[01]\d|2[0-3]):[0-5]\d$/
    const checkLen0 = () => {
      if (time?.length === 0) {
        arr = arr?.map((p, i) => ({
          ...p,
          DeliveryHour: i === index ? "" : p?.DeliveryHour,
          DeliveryMinute: i === index ? "" : p?.DeliveryMinute
        }))
      }
    }
    const checkLen1 = () => {
      if (time?.length === 1) {
        if ((/^[0-2]$/).test(time)) {
          // position 1
          arr = arr?.map((p, i) => ({
            ...p,
            DeliveryHour: i === index ? time?.slice(0, 1) : p?.DeliveryHour,
            DeliveryMinute: i === index ? "" : p?.DeliveryMinute
          }))
        } else {
          checkLen0()
        }
      }
    }
    const checkLen2 = () => {
      if (time?.length === 2) {
        if ((/^(?:[01]\d|2[0-3])$/).test(time)) {
          // position 2
          arr = arr?.map((p, i) => ({
            ...p,
            DeliveryHour: i === index ? time?.slice(0, 2) : p?.DeliveryHour,
            DeliveryMinute: i === index ? "" : p?.DeliveryMinute
          }))
        } else {
          checkLen1()
        }
      }
    }
    const checkLen4 = () => {
      if (time?.length === 4) {
        if ((/^(?:[01]\d|2[0-3]):[0-5]$/).test(time)) {
          // position 4
          arr = arr?.map((p, i) => ({
            ...p,
            DeliveryHour: i === index ? time?.slice(0, 2) : p?.DeliveryHour,
            DeliveryMinute: i === index ? time?.slice(3, 4) : p?.DeliveryMinute
          }))
        } else {
          checkLen2()
        }
      }
    }
    const checkLen5 = () => {
      if (time?.length === 5) {
        if ((/^(?:[01]\d|2[0-3]):[0-5]\d$/).test(time)) {
          // position 5
          arr = arr?.map((p, i) => ({
            ...p,
            DeliveryHour: i === index ? time?.slice(0, 2) : p?.DeliveryHour,
            DeliveryMinute: i === index ? time?.slice(3, 5) : p?.DeliveryMinute
          }))
        } else {
          checkLen4()
        }
      }
    }

    checkLen0()
    checkLen1()
    checkLen2()
    checkLen4()
    checkLen5()

    setOrderDetails(arr)
  }

  const handleOnBlur = (time, index) => {
    if (time.length === 1) {
      handleDeliveryTimeChange("0" + time + ":00", index)
    }
    if (time.length === 2) {
      handleDeliveryTimeChange(time + ":00", index)
    }
    if (time.length === 4) {
      handleDeliveryTimeChange(time?.slice(0, 2) + ":0" + time?.slice(3, 4), index)
    }
  }
  //===============================================================================================

  const handlePriceChange = (price, i) => {
    let arr = [...orderDetails]
    arr[i].Price = price
    setOrderDetails(arr)
  }

  const updateOrderPrice = () => {
    setOrderDetails((prev) => prev?.map((d) => ({
      ...d,
      Price: !d?.ProductName
        ? ""
        : allCateringProduct?.filter((data) => data?.Name?.trim() === d?.ProductName?.trim())?.length
          ? allCateringProduct?.find((data) => data?.Name?.trim() === d?.ProductName?.trim())?.Price
          : d?.Price
    })))
  }

  const GetDepartments = async () => {
    await getDepartment().then((response) => {
      if (response && typeof response === "object" && response?.length > 0) {
        setAllDepartments(response?.filter((department) => department?.Active))
        const defaultDepartment = response?.filter((department) => department?.Active)?.find((department) => department?.IsDefault)
        setInputDepartment({
          value: defaultDepartment?.Id ?? 0,
          label: defaultDepartment?.Name ?? "Select Costcenter"
        })
      } else {
        setAllDepartments([])
      }
    }).catch((error) => {
      setAllDepartments([])
      console.warn((error))
    })
  }

  const scrollToDiv = () => {
    var prodList = document.getElementById("prodList");
    prodList.scrollIntoView();
  }

  const isRoomAvailable = (roomId, startTime, endTime) => {
    const setMeetRoom = allMeetingRoom?.length > 0
      ? allMeetingRoom?.find((meetingRoom) => roomId === meetingRoom?.Id)
      : [];

    const selectedStartTime = moment(startTime).subtract(setMeetRoom?.CateringBuffertime, "m")._d;
    const selectedEndTime = moment(endTime).add(setMeetRoom?.CateringBuffertime, "m")._d;

    const ownRemovedMeetings = allMeetingsTime?.length > 0
      ? allMeetingsTime?.filter((appoint) =>
        !appoint?.IsBufferMeeting &&
        !(moment(moment(appoint?.From)._d).isSame(moment(selectedStartTime).add(setMeetRoom?.CateringBuffertime, "m")._d) &&
          moment(moment(appoint?.To)._d).isSame(moment(selectedEndTime).subtract(setMeetRoom?.CateringBuffertime, "m")._d))
      )
      : [];
    if (
      !ownRemovedMeetings?.some((appointment) => moment(moment(appointment.From)._d).isBetween(selectedStartTime, selectedEndTime)) &&
      !ownRemovedMeetings?.some((appointment) => moment(moment(appointment.To)._d).isBetween(selectedStartTime, selectedEndTime)) &&
      !ownRemovedMeetings?.some((appointment) => moment(selectedStartTime).isBetween(moment(appointment.From)._d, moment(appointment.To)._d)) &&
      !ownRemovedMeetings?.some((appointment) => moment(selectedEndTime).isBetween(moment(appointment.From)._d, moment(appointment.To)._d))
    ) {
      return true;
    }
    return false;
  };

  const CreateUpdateOrder = () => {
    const saveCat = async (data) => {
      setIsSaveOrderLoading(true)
      await createUpdateOrder(data).then((response) => {
        if (!response) {
          toast.success("Order created successfully")

          setInputLocation(0)
          setInputDate(moment().format("YYYY-MM-DD"))
          setInputMeetingRoom(0)
          setInputMeetingTime({ value: "", label: "Select Meeting Room Time" })
        } else if (response && response?.ExceptionMessage) {
          toast.error(response?.ExceptionMessage)
        } else if (typeof response === "string") {
          toast.error(response)
        }
      }).catch((error) => {
        console.warn(error)
      }).finally(() => {
        setIsSaveOrderLoading(false)
      })
    }

    const validationForTime = orderDetails?.map((p, i) => {
      const time = p?.DeliveryMinute ? p?.DeliveryHour + ":" + p?.DeliveryMinute : p?.DeliveryHour
      return {
        ...p,
        isValid: time ? (
          moment(moment(time, "HH:mm")._d).isSame(moment(moment(selectedMeeting?.Start).format("HH:mm"), "HH:mm")._d) ||
          moment(moment(time, "HH:mm")._d).isSame(moment(moment(selectedMeeting?.End).format("HH:mm"), "HH:mm")._d) ||
          moment(moment(time, "HH:mm")._d).isBetween(moment(moment(selectedMeeting?.Start).format("HH:mm"), "HH:mm")._d, moment(moment(selectedMeeting?.End).format("HH:mm"), "HH:mm")._d)
        ) : true
      }
    })

    let inValidOrders = []
    if (inputDeliveryMethod === "Delivery") {
      inValidOrders = validationForTime?.map((d, i) => ({ ...d, Index: i }))?.filter((order) => !order?.isValid)
    } else {
      inValidOrders = []
    }

    if (inValidOrders?.length === 0) {
      const data = {
        "Catering": {
          "DepartmentId": inputDepartment?.value,
          "InvoiceInternal": invoiceInternal,
          "InvoiceExternalDetails": invoiceInternal === false ? invoiceExternalDetails : null,
          "Responsible": inputCanteenUser > 0 ? [inputCanteenUser] : [],
          "NumberOfPeople": inputInternal,
          "ExternalAttendeesPeoples": inputExternal,
          "Discount": inputDiscount,
          "Products": orderDetails?.length > 0
            ? orderDetails?.filter((d) => allCateringProduct?.filter((data) => data?.Name === d?.ProductName)?.length > 0 && d?.NumberOfItems && d?.NumberOfItems > 0)?.map((d) => ({
              ProductId: allCateringProduct?.find((data) => data?.Name === d?.ProductName)?.Id,
              NumberOfItems: d?.NumberOfItems,
              DeliveryHour: inputDeliveryMethod === "Pickup" ? "" : d?.DeliveryHour,
              DeliveryMinute: inputDeliveryMethod === "Pickup" ? "" : d?.DeliveryMinute
            }))
            : [],
          "ProductsOther": orderDetails?.length > 0
            ? orderDetails?.filter((d) => d?.ProductName?.trim() && !allCateringProduct?.some((data) => data?.Name === d?.ProductName) && d?.NumberOfItems)?.map((d) => ({
              Id: 0,
              Name: d?.ProductName,
              NumberOfItems: d?.NumberOfItems,
              Price: d?.Price,
              DeliveryHour: inputDeliveryMethod === "Pickup" ? "" : d?.DeliveryHour,
              DeliveryMinute: inputDeliveryMethod === "Pickup" ? "" : d?.DeliveryMinute
            }))
            : [],
          "Comment": inputSharedComment,
          "InternalComment": inputInternalComment,
          "DeliveryMethod": inputDeliveryMethod,
          "OrderStatusId": inputOrderStatus,
          "OrderedByName": orderByName,
          "OrderedByEmail": orderByEmail,
        },
        "MyDeskMeetingId": selectedMeeting?.Id,
        "MeetingroomId": inputMeetingRoom,
        "ExchangeMeetingId": selectedMeeting?.ExchangeId,
        "ExchangeICalUId": selectedMeeting?.ExchangeICalUId,
      }

      if (
        (invoiceInternal
          ? data?.Catering?.DepartmentId
          :
          //  (
          //   data?.Catering?.InvoiceExternalDetails?.CompanyName &&
          //     data?.Catering?.InvoiceExternalDetails?.Mail
          //     ? emailRegex.test(data?.Catering?.InvoiceExternalDetails?.Mail)
          //     : true
          // )
          (
            data?.Catering?.InvoiceExternalDetails?.CompanyName?.trim() ?
              ((
                data?.Catering?.InvoiceExternalDetails?.CVR ?
                  (data?.Catering?.InvoiceExternalDetails?.CVR?.length === 13 || data?.Catering?.InvoiceExternalDetails?.CVR?.length === 8) :
                  true
              )
                &&
                (data?.Catering?.InvoiceExternalDetails?.Mail ?
                  emailRegex.test(data?.Catering?.InvoiceExternalDetails?.Mail) :
                  true))
              : true
          )
        ) &&
        data?.Catering?.NumberOfPeople + data?.Catering?.ExternalAttendeesPeoples > 0 &&
        data?.Catering?.OrderedByName &&
        data?.Catering?.OrderedByEmail
      ) {
        if (inputDeliveryMethod === "Delivery" && orderDetails.length >= 1 && allMeetingRoom?.find((room) => room?.Id === inputMeetingRoom)?.CateringBuffertime !== 0) {
          if (isRoomAvailable(inputMeetingRoom, selectedMeeting?.Start, selectedMeeting?.End)) {
            saveCat(data)
          } else {
            toast.error(`Not getting ${allMeetingRoom?.find((room) => room?.Id === inputMeetingRoom)?.CateringBuffertime} minutes of buffer time!`);
          }
        } else {
          saveCat(data)
        }
      } else {
        if (invoiceInternal && !data?.Catering?.DepartmentId) {
          toast.error("Costcenter is required!")
        } else if (!invoiceInternal && data?.Catering?.InvoiceExternalDetails?.CVR && data?.Catering?.InvoiceExternalDetails?.CVR?.length !== 8 && data?.Catering?.InvoiceExternalDetails?.CVR?.length !== 13) {
          toast.error("Please Enter CVR/EAN number must be 8/13 digit long !")
        } else if (!invoiceInternal && data?.Catering?.InvoiceExternalDetails?.Mail ? !emailRegex.test(data?.Catering?.InvoiceExternalDetails?.Mail) : false) {
          toast.error("Input valid mail!")
        } else if (!invoiceInternal && !data?.Catering?.InvoiceExternalDetails?.CompanyName) {
          toast.error("External invoice company name required!")
        } else if (data?.Catering?.NumberOfPeople + data?.Catering?.ExternalAttendeesPeoples <= 0) {
          toast.error("Attendees is required!")
        } else if (!data?.Catering?.OrderedByName && !data?.Catering?.OrderedByEmail) {
          toast.error("Order By is required!")
        }
      }
    } else {
      console.log('second')
      setError({ Status: true, Indexs: inValidOrders?.map((order) => order?.Index) })
      scrollToDiv()
      toast?.error(`Please enter valid time between ${moment(selectedMeeting?.Start).format("HH:mm")} - ${moment(selectedMeeting?.End).format("HH:mm")} !`)
    }
  }

  const GetAdminLocation = async () => {
    await getAdminLocation().then((response) => {
      dispatch(changeAllLocationValue(response))
    }).catch((error) => {
      dispatch(changeAllLocationValue([]))
      toast.error(error)
    })
  }

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowValidationMessage(false);
    }
  };

  useEffect(() => {
    if (userSearch.length >= 3) {
      searchColleague(userSearch).then((res) => setColleaguesList(res))
    } else {
      setColleaguesList([])
    }
  }, [userSearch])

  useEffect(() => {
    setValue(selectedMeeting?.CateringInfo ? selectedMeeting?.CateringInfo : {})
  }, [selectedMeeting])

  // GET MEETINGS BY ROOM
  useEffect(() => {
    let subscribed = true

    setInputMeetingTime("")
    if (inputMeetingRoom) {
      setIsLoading(true)
      getMeetingByRoom(inputDate, inputMeetingRoom).then((response) => {
        if (subscribed) {
          setAllMeetingsTime(
            response && typeof response === "object" && response?.length > 0
              ? response?.filter((meeting) => !meeting?.IsBufferMeeting)
              : []
          )
        }
      }).catch((error) => {
        if (subscribed) {
          console.warn(error)
        }
      }).finally(() => {
        if (subscribed) {
          setIsLoading(false)
        }
      })
    } else {
      if (subscribed) {
        setAllMeetingsTime([])
      }
    }
  }, [inputMeetingRoom, inputDate])

  // GET CATERING PRODUCTS
  useEffect(() => {
    let subscribed = true

    if (inputMeetingTime?.value) {
      const payloadData = {
        locationId: inputLocation?.value,
        date: inputDate,
        startTimeMeetingHour: inputMeetingTime?.label?.slice(0, 2),
        startTimeMeetingMinute: inputMeetingTime?.label?.slice(3, 5),
        endTimeMeetingHour: inputMeetingTime?.label?.slice(8, 10),
        endTimeMeetingMinute: inputMeetingTime?.label?.slice(11, 13),
      }

      getCateringProduct(payloadData).then((response) => {
        if (subscribed) {
          let arr = []
          response?.length > 0 && response?.forEach((d) => {
            d?.Products?.length > 0 && arr?.push(...d?.Products)
          })
          setAllCateringProduct(arr)
        }
      }).catch((error) => {
        if (subscribed) {
          console.warn(error)
        }
      })
    } else {
      if (subscribed) {
        setAllCateringProduct([])
      }
    }

    return () => { subscribed = false }
  }, [inputMeetingTime, inputLocation?.value, inputDate])

  // GET MEETING ROOM BY LOCATION
  useEffect(() => {
    let subscribed = true

    setInputMeetingRoom(0)
    if (inputLocation?.value) {
      setIsLoading(true)
      getMeetingRoomByLocation(inputLocation?.value).then((response) => {
        if (subscribed) {
          setAllMeetingRoom(typeof response === "object" && response?.length > 0 ? response : [])
        }
      }).catch((error) => {
        if (subscribed) {
          console.warn(error)
        }
      }).finally(() => {
        if (subscribed) {
          setIsLoading(false)
        }
      })
    } else {
      if (subscribed) {
        setAllMeetingRoom([])
      }
    }

    return () => { subscribed = false }
  }, [inputLocation?.value])

  useEffect(() => {
    GetDepartments()
    GetAdminLocation()
  }, [])

  useEffect(() => {
    if (invoiceInternal === true) {
      setInvoiceExternalDetails({
        CompanyName: "",
        Address: "",
        Zip: "",
        CVR: "",
        Att: "",
        Mail: ""
      })
    }
  }, [invoiceInternal])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <>
      {(isLoading || isSaveOrderLoading) && <Loader />}
      <div className="pagetitle mb-4">
        <h1>Create Order</h1>
      </div>

      {/* ========================== Input Section ========================== */}
      {/* ========================== Input Section ========================== */}
      <section className="section mb-4">
        {/* Input Location/Date */}
        <div className="row">
          {/* Input Location */}
          <div className="col-6">
            <div className="inputSec-icon mb-3">
              <Select
                ref={locationRef} openMenuOnFocus={true}
                value={[inputLocation]}
                onChange={(e) => setInputLocation(e)}
                options={allLocations?.length > 0
                  ? [{ value: 0, label: "Select Location" }, ...allLocations?.map((d) => ({ value: d?.Id, label: d?.Name }))]
                  : [{ value: 0, label: "Select Location" }]
                }
                className="form-control p-0" placeholder="Select Location"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    border: "none",
                    boxShadow: "none",
                    padding: "8px 10px",
                    borderRadius: 8,
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null
                }}
              />
              <span onClick={() => locationRef.current?.focus()} className="custom-drop-down-span">
                <img className="custom-drop-down-image" alt="icon-user" src={iconArrow} />
              </span>
            </div>
          </div>

          {/* Input Date */}
          <div className="col-6">
            <div className="inputSec-icon">
              <ReactDatePicker
                selected={moment(inputDate)._d}
                onChange={(date) => setInputDate(moment(date).format("YYYY-MM-DD"))}
                className="form-control"
                dateFormat="dd.MM.yyyy"
                calendarStartDay={1}
              />
            </div>
          </div>
        </div>

        {/* Input MeetingRoom/MeetingTime */}
        <div className="row">
          {/* Input MeetingRoom */}
          <div className="col-6">
            <div className="inputSec-icon">
              <Select
                ref={meetingRef} openMenuOnFocus={true} isDisabled={!inputLocation?.value}
                value={inputMeetingRoom && allMeetingRoom?.length > 0 && [{ value: allMeetingRoom?.find((d) => d?.Id === inputMeetingRoom)?.Id, label: allMeetingRoom?.find((d) => d?.Id === inputMeetingRoom)?.Name }]}
                onChange={(e) => setInputMeetingRoom(Number(e?.value))}
                options={allMeetingRoom?.length > 0
                  ? [{ value: 0, label: "Select Meeting Room" }, ...allMeetingRoom?.map((d) => ({ value: d?.Id, label: d?.Name }))]
                  : [{ value: 0, label: "Select Meeting Room" }]
                }
                className="form-control p-0" placeholder="Select Meeting Room"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    border: "none",
                    boxShadow: "none",
                    padding: "8px 10px",
                    borderRadius: 8,
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null
                }}
              />
              <span onClick={() => meetingRef.current?.focus()} className="custom-drop-down-span">
                <img className="custom-drop-down-image" alt="icon-user" src={iconArrow} />
              </span>
            </div>
          </div>

          {/* Input MeetingTime */}
          <div className="col-6">
            <div className="inputSec-icon">
              <Select
                ref={meetingTimeRef} openMenuOnFocus={true} isDisabled={!inputLocation?.value || !inputMeetingRoom}
                value={[inputMeetingTime]}
                onChange={(e) => setInputMeetingTime(e)}
                options={allMeetingsTime?.length > 0 ? (
                  [{ value: "", label: "Select Meeting Time" },
                  ...allMeetingsTime?.map((d) => ({
                    value: d?.ExchangeICalUId,
                    label: `${moment(d?.Start).format("HH:mm")} - ${moment(d?.End).format("HH:mm")}`
                  }))]
                ) : (
                  [{ value: "", label: "Select Meeting Time" }]
                )}
                className="form-control p-0" placeholder="Select Meeting Time"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    border: "none",
                    boxShadow: "none",
                    padding: "8px 10px",
                    borderRadius: 8,
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null
                }}
              />
              <span onClick={() => meetingTimeRef.current?.focus()} className="custom-drop-down-span">
                <img className="custom-drop-down-image" alt="icon-user" src={iconArrow} />
              </span>
            </div>
          </div>
        </div>
      </section>

      {/* ========================== Edit Order Section ========================== */}
      {/* ========================== Edit Order Section ========================== */}
      {inputMeetingTime?.value && (
        <>
          <div className="card card-revert mt-3 cardhide">
            <div className="row mb-3 mt-2" id="prodList">
              <div className="col-9">
                <div className="pagetitle mt-2"><h2>Orders</h2></div>
              </div>
            </div>

            {/*================= Orders Table =================*/}
            <div className="table-responsive table-custom edit" >
              <table className="table table-hover valignM">
                <tbody>
                  {orderDetails?.length > 0 ? (orderDetails?.map((order, i) =>
                    <tr key={i}>
                      {/* Input Product */}
                      <td className="position-relative">
                        <CreatableSelect
                          isClearable
                          placeholder="Select Product"
                          value={order?.ProductName && [{ value: order?.Id, label: order?.ProductName }]}
                          onChange={(e) => e && handleProductChange(Number(e.value), i, e)}
                          options={allCateringProduct?.map((d) => ({ value: d?.Id, label: d?.Name }))}
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                            ClearIndicator: () => null,
                          }}
                        />
                        {order?.ProductName && allCateringProduct?.filter((d) => d?.Name?.trim() === order?.ProductName?.trim())?.length > 0 &&
                          <>
                            <i
                              className="fa fa-selectedMeeting-circle position-absolute tool-tip"
                              data-tooltip-place="top" data-tooltip-id="my-tooltip"
                              data-tooltip-content={allCateringProduct?.find((d) => d?.Name?.trim() === order?.ProductName?.trim())?.Notes} />
                            <Tooltip id="my-tooltip" />
                          </>
                        }
                      </td>

                      {/* Input Delivery Time */}
                      {inputDeliveryMethod === "Delivery" && (
                        <td className="dlvryTime-td">
                          <InputMask
                            mask="HH:mm"
                            onBlur={(e) => handleOnBlur(e.target.value, i)}
                            replacement={{ H: /\d/, m: /\d/ }}
                            value={(() => {
                              const time = orderDetails?.find((prod, index) => index === i)
                              return time?.DeliveryMinute ? time?.DeliveryHour + ":" + time?.DeliveryMinute : time?.DeliveryHour
                            })()}
                            onChange={(e) => handleDeliveryTimeChange(e.target.value, i)}
                            separate
                            className="form-control text-center"
                            placeholder="HH:mm"
                            style={{ border: error?.Status && error?.Indexs?.some((prod) => prod === i) && "1px solid red" }}
                          />
                        </td>
                      )}

                      {/* Input Quantity */}
                      <td className="qty-td">
                        <input
                          value={order?.NumberOfItems}
                          onChange={(e) => Number.isInteger(Number(e.target.value)) && handleQtyChange(Number(e.target.value), i)}
                          min={0}
                          max={100}
                          type="number"
                          className="form-control text-center"
                          placeholder="Qty." />
                      </td>

                      {/* Input Price */}
                      <td className="price-td">
                        <input
                          type="number"
                          value={order?.Price ?? ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === "" || Number(value) >= 0) {
                              handlePriceChange(value === "" ? "" : Number(value), i);
                            }
                          }}
                          disabled={allCateringProduct.some((d) => d?.Name.trim() === order?.ProductName.trim())}
                          className="form-control text-center"
                          placeholder="Price"
                        />
                      </td>

                      {/* Delete Button */}
                      <td className="dlt-td">
                        <button onClick={() => removeRow(order)} type="button" className="btn btn-secondary btn-icon">
                          <img alt="icon-trash" src={iconDelete} />
                        </button>
                      </td>
                    </tr>
                  )
                  ) : (
                    <tr>
                      <td colSpan="4">
                        <span className="txt-gray d-block no-order">
                          No Orders</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/*================= Discount & Amount Input =================*/}
              <div className="discount d-flex justify-content-end">
                <div className="col-8 p-3 d-flex justify-content-end align-items-center">
                  <span className="txt500">Discount</span>
                </div>

                <div className="col-4 d-flex justify-content-end align-items-center">
                  {/* Input Discount */}
                  <div className="input-group">
                    <input
                      min={0} max={100}
                      value={inputDiscount}
                      onChange={(e) => {
                        if (e.target.value >= 0 && e.target.value <= 100) {
                          setInputDiscount(e.target.value === 0 ? "" : e.target.value)
                          setDisPercent(e.target.value === 0 ? "" : e.target.value)
                          setDiscountTotalDisplay(true)
                          setDiscountAmountDisplay(false)
                        }
                      }}
                      type="number"
                      className="discount-input form-control form-control-sm text-center"
                      placeholder="Discount"
                      style={{ borderRight: "none", boxShadow: "none" }}
                    />
                    <span className="input-group-text" style={{ backgroundColor: "white", border: "1px solid #e8ecef" }}><i className="fa-solid fa-percent" /></span>
                  </div>

                  {/* Discount Amount */}
                  <div className="input-group">
                    <input
                      type="number"
                      value={inputAmount}
                      onChange={(e) => {
                        if (e.target.value >= 0 && e.target.value <= calPrice) {
                          setInputAmount(e.target.value === 0 ? "" : e.target.value)
                          setDiscountAmountDisplay(true)
                          setDiscountTotalDisplay(false)
                        }
                      }}
                      className="discount-amount form-control form-control-sm text-center"
                      placeholder="Amount"
                      style={{ borderRight: "none", boxShadow: "none" }}
                    />
                    <span className="input-group-text" style={{ backgroundColor: "white", border: "1px solid #e8ecef" }}>Kr</span>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <div className="col-8 p-3 d-flex justify-content-end align-items-center">
                  <span className="txt500">Total</span>
                </div>

                {/* Discount Amount */}
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <span className="txt500">
                    {discountTotalDisplay
                      ? isNaN(discountTotal.amount)
                        ? 0
                        : discountTotal.amount
                      : discountAmountDisplay
                        ? isNaN(discountAmount.amount)
                          ? 0
                          : discountAmount.amount
                        : discountTotal.amount
                    }&nbsp;
                    {meetingLocation?.Currency === "" ? "Dkk" : meetingLocation?.Currency}
                  </span>
                </div>
              </div>
            </div>

            {/*================= Price Total and Add Button =================*/}
            <div className="row mb-2 mt-2">
              <div className="col-9">
              </div>
              <div className="col-3 text-end addNewRowItem">
                <button onClick={() => addRow()} type="button" className="btn btn-secondary btn-icon btn-icon-lg">
                  <img src={iconAdd} alt="icon-add" />
                </button>
              </div>
            </div>

            {/*================= Order Details =================*/}
            <div className="card-content orderDetails">
              {/*================= Cost Center =================*/}
              <div className="row costcenter list mt-3">
                <div className="col-sm-12 col-md-12 col-lg-3 col-xl-xl-3 txt-md mb-2">
                  <span className="txt500">Costcenter/External customer<span className="required">*</span></span>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-9 col-xl-xl-9 txt-md mb-2">
                  <div className="d-flex mb-3">
                    <div className="form-check d-flex align-items-center me-3">
                      <div className="d-flex align-items-center">
                        <label className="form-check-label me-2" htmlFor="internal">
                          <span className="txt500">Internal</span>
                        </label>
                        <input
                          type="radio"
                          checked={invoiceInternal === true}
                          onChange={() => setInvoiceInternal(true)}
                          id="internal" title="Internal"
                        />
                      </div>
                    </div>
                    <div className="form-check d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <label className="form-check-label me-2" htmlFor="external">
                          <span className="txt500">External</span>
                        </label>
                        <input
                          type="radio"
                          checked={invoiceInternal === false}
                          onChange={() => setInvoiceInternal(false)}
                          id="external" title="External"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="">
                    {invoiceInternal ? (
                      <div>
                        <div className="inputSec-icon">
                          <Select
                            ref={costCenterRef} openMenuOnFocus={true}
                            value={inputDepartment}
                            onChange={(value) => setInputDepartment(value)}
                            options={allDepartments?.length > 0 ? (
                              [
                                { value: 0, label: "Select Costcenter" },
                                ...allDepartments?.filter((d) => d?.Active)?.find((a) => a?.IsDefault)?.length > 0 ? allDepartments?.filter((d) => d?.Active)?.find((a) => a?.IsDefault) : allDepartments?.filter((d) => d?.Active)?.map((data) => ({ value: data?.Id, label: data?.Name }))]
                            ) : (
                              [{ value: 0, label: "Select Costcenter" }]
                            )}
                            className="form-select p-0" placeholder="Select Costcenter"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                border: "none",
                                boxShadow: "none",
                                padding: "8px 10px",
                                borderRadius: 8,
                              }),
                            }}
                            components={{
                              IndicatorSeparator: () => null,
                              DropdownIndicator: () => null
                            }}
                          />
                          <span onClick={() => costCenterRef.current?.focus()} className="custom-drop-down-span">
                            <img className="custom-drop-down-image" alt="icon-user" src={iconArrow} />
                          </span>
                        </div>
                        <span className="txt-sm txt-gray px-1">
                          {allDepartments?.find((d) => d?.Id === inputDepartment?.value)?.ManagerName}
                        </span>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-6 col-md-6 col-xl-6 col-xl-6 mb-3">
                          <CreatableSelect
                            isClearable
                            className="CreatableSelect"
                            placeholder="Search Customer"
                            value={invoiceExternalDetails?.CompanyName && [{ value: invoiceExternalDetails?.CompanyName, label: invoiceExternalDetails?.CompanyName }]}
                            onChange={(e) => {
                              const companyName = e ? e.value : "";
                              const filteredCompany = allCompanyResponse.filter((d) => d?.CompanyName === companyName)[0]

                              const updatedAddress = filteredCompany ? filteredCompany?.Address : "";
                              const updatedZip = filteredCompany ? filteredCompany?.Zip : "";
                              const updatedCVR = filteredCompany ? filteredCompany?.CVR : "";
                              const updatedAtt = filteredCompany ? filteredCompany?.Att : "";
                              const updatedMail = filteredCompany ? filteredCompany?.Mail : "";

                              setInvoiceExternalDetails((prev) => ({
                                ...prev,
                                CompanyName: companyName,
                                Address: updatedAddress,
                                Zip: updatedZip,
                                CVR: updatedCVR,
                                Att: updatedAtt,
                                Mail: updatedMail
                              }));
                            }}
                            onInputChange={(event) => setCompnayQueryString(event)}
                            options={allCompanyResponse?.map((d) => ({ value: d?.CompanyName, label: d?.CompanyName }))}
                            components={{
                              IndicatorSeparator: () => null,
                              DropdownIndicator: () => null,
                              ClearIndicator: () => null,
                            }}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                boxShadow: "none",
                                borderRadius: 8,
                                padding: "8px 10px",
                                fontSize: "18px",
                                border: "1px solid #e8ecef",
                                outline: "none"
                              }),
                            }}
                          />
                        </div>
                        <div className="col-6 col-md-6 col-xl-6 col-xl-6 mb-3">
                          <input
                            type="text"
                            value={invoiceExternalDetails?.Address}
                            onChange={(e) => setInvoiceExternalDetails((prev) => ({ ...prev, Address: e.target.value }))}
                            className="form-control"
                            placeholder="Address"
                          />
                        </div>
                        <div className="col-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                          <input
                            type="text"
                            value={invoiceExternalDetails?.Zip}
                            onChange={(e) => {
                              const value = e.target.value
                              if (value) {
                                if (!isNaN(Number(value)) && Number(value) >= 0 && value?.length <= 8) {
                                  setInvoiceExternalDetails((prev) => ({ ...prev, Zip: value }))
                                }
                              } else {
                                setInvoiceExternalDetails((prev) => ({ ...prev, Zip: "" }))
                              }
                            }}
                            className="form-control"
                            placeholder="Zip"
                          />
                        </div>

                        <div className="col-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                          <input
                            type="text"
                            value={invoiceExternalDetails?.CVR ?? ""}
                            onChange={(e) => {
                              const value = e.target.value
                              if (value) {
                                if (!isNaN(Number(value)) && Number(value) >= 0 && value?.length <= 13) {
                                  setInvoiceExternalDetails((prev) => ({ ...prev, CVR: value }))
                                }
                              } else {
                                setInvoiceExternalDetails((prev) => ({ ...prev, CVR: "" }))
                              }
                            }}
                            className={`form-control ${invoiceExternalDetails?.CVR && (invoiceExternalDetails?.CVR?.length !== 8 && invoiceExternalDetails?.CVR?.length !== 13) ? "border-danger" : ""}`}
                            placeholder="CVR/EAN-Number"
                          />
                          {invoiceExternalDetails?.CVR && (invoiceExternalDetails?.CVR?.length !== 8 && invoiceExternalDetails?.CVR?.length !== 13) && (
                            <span className="text-danger">CVR or EAN Number must be either 8 or 13 digits</span>
                          )}
                        </div>
                        <div className="col-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                          <input
                            type="text"
                            value={invoiceExternalDetails?.Att}
                            onChange={(e) => setInvoiceExternalDetails((prev) => ({ ...prev, Att: e.target.value }))}
                            className="form-control"
                            placeholder="ATT"
                          />
                        </div>
                        <div className="col-12 col-md-6 col-xl-6 col-xl-6 mb-3">
                          <input
                            type="text"
                            value={invoiceExternalDetails?.Mail}
                            onChange={(e) => setInvoiceExternalDetails((prev) => ({ ...prev, Mail: e.target.value }))}
                            className="form-control"
                            placeholder="Mail"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/*================= Delivery =================*/}
              <div className="row list mt-3">
                <div className="col-3 txt-md mt-3">
                  <span className="txt500">Delivery<span className="required">*</span></span>
                </div>
                <div className="col-9 txt-md mb-2">
                  <div className="inputSec-icon">
                    <Select
                      ref={deliveryRef} openMenuOnFocus={true}
                      value={[{ value: inputDeliveryMethod, label: inputDeliveryMethod }]}
                      onChange={(e) => setInputDeliveryMethod(e.value)}
                      options={[{ value: "Delivery", label: "Delivery" }, { value: "Pickup", label: "Pickup" }]}
                      className="form-select p-0" placeholder="Select Delivery Method"
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          border: "none",
                          boxShadow: "none",
                          padding: "8px 10px",
                          borderRadius: 8,
                        }),
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null
                      }}
                    />
                    <span onClick={() => deliveryRef.current.focus()} className="custom-drop-down-span">
                      <img className="custom-drop-down-image" alt="icon-user" src={iconArrow} />
                    </span>
                  </div>
                </div>
              </div>

              <div className="row list mt-3">
                {/*================= Internal Attendees =================*/}
                <div className="txt-md mb-2 mt-2 col-3">
                  <span className="txt500">Internal<span className="required">*</span></span>
                </div>
                <div className="txt-md mb-2 col-3">
                  <input type="number" value={inputInternal} onChange={(e) => setInputInternal(Number(e.target.value))} min={0} max={100} className="form-control form-control2" placeholder="Internal" />
                </div>

                {/*================= External Attendees =================*/}
                <div className="txt-md mb-2 mt-2 col-3 text-center">
                  <span className="txt500">External</span>
                </div>
                <div className="txt-md mb-2 col-3">
                  <input type="number" value={inputExternal} onChange={(e) => setInputExternal(Number(e.target.value))} min={0} max={100} className="form-control form-control2" placeholder="External" />
                </div>

                {/*================= Shared Comment =================*/}
                <div className="txt-md mb-2 mt-2">
                  <span className="txt500">Shared Comment</span>
                  <textarea value={inputSharedComment} onChange={(e) => setInputSharedComment(e.target.value)} className="form-control mt-2" rows={3} placeholder="Shared Comment" />
                </div>

                {/*================= Internal Comment =================*/}
                <div className="txt-md mb-2 mt-2">
                  <span className="txt500">Internal Comment</span>
                  <textarea value={inputInternalComment} onChange={(e) => setInputInternalComment(e.target.value)} className="form-control mt-2" rows={3} placeholder="Internal Comment" />
                </div>

                {/*================= Delivered By =================*/}
                <div className="txt-md mt-2">
                  <span className="txt500">Delivery By</span>
                  <select value={inputCanteenUser} onChange={(e) => setInputCanteenUser(Number(e.target.value))} className="form-select form-control mt-2" placeholder="Select Deliver by">
                    <option value={0}>Select Deliver by</option>
                    {allCanteenUser && allCanteenUser?.length > 0 && (
                      allCanteenUser?.map((d, i) =>
                        <option key={i} value={d?.Id}>{d?.Name}</option>
                      )
                    )}
                  </select>
                </div>

                {/* ================= Order By =================*/}
                <div className="mt-2">
                  <span className="txt500">Order By<span className="required">*</span></span>
                  <div className="w-100 custom-search-group-create hover mt-2">
                    <div
                      className="d-block"
                      ref={wrapperRef}
                      onClick={handleWrapperClick}
                    >
                      <ReactSelect
                        className="basic-single"
                        placeholder="Search order by"
                        value={orderByName ? { label: orderByName, value: { Name: orderByName } } : null}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        options={colleagueOptions}
                        formatOptionLabel={formatOptionLabel}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                          ClearIndicator: () => null,
                        }}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: "none",
                            borderRadius: 8,
                            padding: "8px 10px",
                            fontSize: "18px",
                            border: "1px solid #e8ecef",
                            outline: "none"
                          }),
                        }}
                      />
                    </div>

                    {(showValidationMessage && userSearch?.length < 3) && (
                      <div className="search-wrapper" >
                        <div className="item-card">
                          <p className="txt-md">Please enter {3 - userSearch?.length} or more characters</p>
                        </div>
                      </div>
                    )}
                    {/* <input
                        type="text"
                        value={orderByName || ""}
                        onChange={(e) => {
                          if (orderBySearchStatus) {
                            orderBySearch.current?.focus();
                          }
                          setUserSearch(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Search order by"
                      /> */}

                    {/* {orderBySearchStatus && (
                      <div className="search-wrapper">
                        <input
                          value={userSearch}
                          ref={orderBySearch}
                          onChange={(e) => setUserSearch(e.target.value)}
                          className="form-control search-input"
                          placeholder="search"
                          autoFocus
                        />
                        {userSearch.length < 3 && (
                          <div className="item-card">
                            <p className="txt-md">Please enter {3 - userSearch?.length} or more characters</p>
                          </div>
                        )}
                        {colleaguesList?.map((user, userIndex) => (
                          <div
                            key={userIndex}
                            className="item-card item-card-hover"
                            onClick={() => {
                              setOrderByName(user?.Name);
                              setOrderByEmail(user?.UPN);
                              setUserSearch("");
                              setColleaguesList([]);
                              setOrderBySearchStatus(false);
                            }}
                          >
                            <b className="txt-md mb-0">{user?.Name}</b>
                            <p className="txt-sm mb-0">{user?.UPN}</p>
                          </div>
                        ))}
                      </div>
                    )} */}


                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <button onClick={() => CreateUpdateOrder()} className="btn btn-primary btn-lg btn-full">Save</button>
            </div>
          </div>
        </>
      )}
    </>
  )
}
