import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  AllLocations: [],
  AllCanteenUsers: [],
  AllCateringOrderStatus: [],
  AllDepartment: [],
};

export const APIResponseSlice = createSlice({
  name: "API",
  initialState,
  reducers: {
    changeAllLocationValue: (state, action) => {
      state.AllLocations = action.payload;
    },
    changeAllCanteenUsersValue: (state, action) => {
      state.AllCanteenUsers = action.payload;
    },
    changeAllCateringOrderStatusValue: (state, action) => {
      state.AllCateringOrderStatus = action.payload;
    },
    changeAllDepartmentValue: (state, action) => {
      state.AllDepartment = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  changeAllLocationValue,
  changeAllCanteenUsersValue,
  changeAllCateringOrderStatusValue,
  changeAllDepartmentValue
} = APIResponseSlice.actions;

export default APIResponseSlice.reducer;
