import React, { useState, useEffect, useCallback } from "react"
import Routes from "./Routes"
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser"
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { loginRequest } from "./authConfig"
import { useDispatch, useSelector } from "react-redux"
import { changeAccessToken, changeUserName, changeUserEmail } from "./store/slices/MSALSlice"
import moment from "moment"
import { scope } from "./const"

// =================== Vendor Css Files ===================
import "./assets/vendor/bootstrap/css/bootstrap.min.css"
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css"
import "./assets/vendor/boxicons/css/boxicons.min.css"
import "./assets/vendor/remixicon/remixicon.css"

// =================== Template Main CSS File ===================
import "./assets/css/style.css"
import "./assets/css/custom.css"

import "react-confirm-alert/src/react-confirm-alert.css" // Import css

export default function App() {
  const accessToken = useSelector((state) => state.MSALAuth.accessToken)
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts, inProgress } = useMsal()

  const [intervalTimeForAccessToken, setIntervalTimeForAccessToken] = useState(3600000)

  const dispatch = useDispatch()

  const handleLogin = useCallback(() => {
    instance.loginRedirect(loginRequest).catch((error) => console.warn(error))
  }, [instance])

  const setAccessTokenData = useCallback((accessTokenResponse) => {
    // Acquire token silent success
    const accessToken = accessTokenResponse.accessToken

    localStorage.setItem("accessTokenResponse", JSON.stringify(accessTokenResponse))
    localStorage.setItem("token", accessToken)

    dispatch(changeAccessToken(accessToken))

    setIntervalTimeForAccessToken((moment(accessTokenResponse?.expiresOn).diff(moment()._d, "s") + 1) * 1000)
  }, [dispatch])

  useEffect(() => {
    const interval = setInterval(() => {
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          setAccessTokenData(accessTokenResponse)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    }, intervalTimeForAccessToken)

    return () => { clearInterval(interval) }
  }, [accounts, accessToken, inProgress, instance, setAccessTokenData, intervalTimeForAccessToken])

  useEffect(() => {
    if (accounts && accounts?.length > 0) {
      dispatch(changeUserName(isAuthenticated ? accounts[0]?.name : ""))
      dispatch(changeUserEmail(isAuthenticated ? accounts[0]?.username : ""))
    }
  }, [isAuthenticated, accounts, dispatch])

  useEffect(() => {
    instance.handleRedirectPromise().then((response) => {
      if (response) {
        setAccessTokenData(response)
      } else if (accounts?.length === 0) {
        handleLogin()
      } else if (accounts?.length === 1) {
        const accessTokenRequest = { scopes: [scope], account: accounts[0] }

        if (inProgress === InteractionStatus.None) {
          instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            setAccessTokenData(accessTokenResponse)
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest)
            }
            console.warn(error)
          })
        }
      } else if (accounts?.length > 1) {
        // Add your account choosing logic here
        const accessTokenRequest = { scopes: [scope], account: accounts[0] }

        if (inProgress === InteractionStatus.None) {
          instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            setAccessTokenData(accessTokenResponse)
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest)
            }
            console.warn(error)
          })
        }
      }
    }).catch((error) => {
      console.warn(error)
    })
  }, [accounts, handleLogin, inProgress, instance, setAccessTokenData])

  return (
    <AuthenticatedTemplate>
      {accessToken && <Routes />}
    </AuthenticatedTemplate>
  )
}