import React from 'react'
import { BarLoader } from 'react-spinners'
import "./Loader.css"

import myDeskLogo from "../../assets/img/mydesk-logo.png"

export default function Loader() {
    return (
        <div className="loading-screen">
            <div className="loading-animation">
                <img src={myDeskLogo} alt="MyDesk-logo" className="loading-screen-logo mb-2" />
                <div className="loading-bar" >
                    <BarLoader className='loader-span' color="#0073b1" />
                </div>
            </div>
        </div>
    )
}
