import { LogLevel } from "@azure/msal-browser"
import { clientId, scope } from "./const"

const ua = window.navigator.userAgent
const msie = ua.indexOf("MSIE ")
const msie11 = ua.indexOf("Trident/")
const msedge = ua.indexOf("Edge/")
const firefox = ua.indexOf("Firefox")
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: "https://login.microsoftonline.com/common/",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },

  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },

  system: {
    allowNativeBroker: false, // Disables native brokering support
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message)
    //         return
    //       case LogLevel.Info:
    //         console.info(message)
    //         return
    //       case LogLevel.Verbose:
    //         console.debug(message)
    //         return
    //       case LogLevel.Warning:
    //         console.warn(message)
    //         return
    //       default:
    //         return
    //     }
    //   },
    // },
  },
}

export const loginRequest = {
  scopes: [scope],
}