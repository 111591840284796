import { configureStore } from "@reduxjs/toolkit"
import APIResponseSlice from "./slices/APIResponseSlice"
import MSALSlice from "./slices/MSALSlice"
import CateringOrderInputStatesSlice from "./slices/CateringOrderInputStatesSlice"

export const store = configureStore({
  reducer: {
    MSALAuth: MSALSlice,
    APIResponse: APIResponseSlice,
    InputStates: CateringOrderInputStatesSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})
