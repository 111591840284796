import React, { useEffect, useState } from "react"
import "./Header.css"
import { Link } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import { useDispatch, useSelector } from "react-redux"
import { changeAuthenticated } from "../../store/slices/MSALSlice"
import { getCanteenUserProfile } from "../../services/ApiServices"
import { loginRequest } from "../../authConfig"

// ==== Imported Image ====
import cipLogo from "../../assets/img/Cip-logo.png"
import iconChevronDown from "../../assets/img/icon-chevron-down.svg"
import userThumb from "../../assets/img/user_thumb.jpg"
import { changeDateValue, changeLocationValue, changeStatusValue, changeUserValue } from "../../store/slices/CateringOrderInputStatesSlice"
import moment from "moment"
import { localStorageLocationKey } from "../../const"

export default function Header() {
  const selectedLocation = useSelector((state) => state.InputStates.inputLocation)

  const [profileDropdown, setProfileDropdown] = useState(false);
  const [profileDropdownHoverStatus, setProfileDropdownHoverStatus] = useState(false)
  const [userImageUrl, setUserImageUrl] = useState("")

  const { instance } = useMsal()
  const dispatch = useDispatch()
  const body = document.body.classList

  const userName = useSelector((state) => state.MSALAuth.userName)
  const userEmail = useSelector((state) => state.MSALAuth.userEmail)

  const toggleSideBar = () => {
    body.contains("toggle-sidebar")
      ? body.remove("toggle-sidebar")
      : body.add("toggle-sidebar")
  }

  const handleLogout = () => {
    instance.logoutRedirect(loginRequest);
    dispatch(changeAuthenticated(false))
  }

  const setDefaultOrderInputValue = () => {
    dispatch(changeUserValue(""))
    dispatch(changeLocationValue(selectedLocation))
    dispatch(changeDateValue(moment().format("YYYY-MM-DD")))
    dispatch(changeStatusValue([]))
  }

  useEffect(() => {
    localStorage?.setItem(localStorageLocationKey, JSON.stringify(selectedLocation))
  }, [selectedLocation])

  useEffect(() => {
    userEmail && getCanteenUserProfile(userEmail).then((response) => setUserImageUrl(response))
  }, [userEmail])

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="header-inner" >
          <i onClick={() => toggleSideBar()} className="bi bi-list toggle-sidebar-btn" />
          <div className="d-flex align-items-center justify-content-between">
            <Link to="/" className="logo d-flex align-items-center">
              <img className="header-logo" src={cipLogo} alt="MyDesk" />
            </Link>
          </div>
        </div>

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown">
              <Link
                className="nav-link gap-2 nav-profile d-flex align-items-center pe-0 show"
                data-bs-toggle="dropdown" aria-expanded="true"
              >
                <img
                  src={userImageUrl?.Image ? userImageUrl?.Image : userThumb}
                  className="rounded-circle currentUserProfile" alt="Profile"
                />
                <span className="d-none d-md-block dropdown-toggle m-0">
                  Hi, <span>{userName}</span>
                </span>
                <button
                  className="dropdown" tabIndex={0}
                  onBlur={() => !profileDropdownHoverStatus && setProfileDropdown(false)}
                  onClick={() => setProfileDropdown(!profileDropdown)}
                >
                  <i className="icon">
                    <img src={iconChevronDown} alt="icon-chevron-down" />
                  </i>
                </button>
              </Link>

              {profileDropdown && (
                <ul
                  className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile position-absolute m-0 show"
                  data-popper-placement="bottom-end"
                  onMouseEnter={() => setProfileDropdownHoverStatus(true)}
                  onMouseLeave={() => setProfileDropdownHoverStatus(false)}
                  style={{
                    inset: "0px 0px auto auto",
                    transform: "translate3d(0px, 54px, 0px)",
                  }}
                >
                  <li className="dropdown-header">
                    <h6 id="hDisplayName">{userName}</h6>
                    <span id="spADUserMAil">{userEmail}</span>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <Link
                      to="#"
                      onClick={() => handleLogout()}
                      className="dropdown-item d-flex align-items-center"
                    >
                      <i className="bi bi-box-arrow-right" />
                      <span>Sign Out</span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </nav>
      </header>

      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link to="/" onClick={() => { setDefaultOrderInputValue(); toggleSideBar() }} className="nav-link">
              <i className="bi bi-grid" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/order/create-order" onClick={() => toggleSideBar()} className="nav-link">
              <i className="bi bi-cart" />
              <span>Order</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/Overview" onClick={() => toggleSideBar()} className="nav-link">
              <i className="bi bi-info-circle" />
              <span>Overview</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/users" onClick={() => toggleSideBar()} className="nav-link">
              <i className="bi bi-person" />
              <span>Users</span>
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
}
