import React, { useEffect, useState } from "react";
import { Route, Routes as DomRouter } from "react-router-dom"
import { getCanteenUser } from "./services/ApiServices"
import { changeAllCanteenUsersValue } from "./store/slices/APIResponseSlice"
import { useDispatch } from "react-redux"
import { toast } from "react-hot-toast"

import Loader from "./components/Loader/Loader"
import Header from "./components/Header/Header"
import Order from "./components/Order/Order"
import Overview from "./components/Overview/Overview"
import CreateOrder from "./components/CreateOrder/CreateOrder"
import OrderDetails from "./components/OrderDetails/OrderDetails"
import User from "./components/User/User"
import Error from "./components/Error/Error"

export default function Routes() {
  const [isLoading, setIsLoading] = useState(true)
  const [hasEnoughRights, setHasEnoughRights] = useState(undefined)

  const dispatch = useDispatch()

  useEffect(() => {
    setIsLoading(true)
    getCanteenUser().then((response) => {
      if (response?.Status === 200) {
        dispatch(changeAllCanteenUsersValue(response?.Data))
        setHasEnoughRights(true)
        setIsLoading(false)
      } else if (response?.Status === 403) {
        setHasEnoughRights(false)
        setIsLoading(false)
      }
    }).catch((error) => {
      setIsLoading(false)
      toast.error(error)
    })
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      {hasEnoughRights === true &&
        <>
          <Header />
          <main id="main" className="main">
            <DomRouter>
              <Route path="/" element={<Order />} />
              <Route path="/Overview" element={<Overview />} />
              <Route path="/order/create-order" element={<CreateOrder />} />
              <Route path="/order-details/:orderId" element={<OrderDetails />} />
              <Route path="/users" element={<User />} />
              <Route path="*" element={<Error Status={404} Message={"The page you are looking for doesn't exist."} />} />
            </DomRouter>
          </main>
        </>
      }
      {hasEnoughRights === false &&
        <Error Status={403} Message={"You do not have permission to access this resource. Please try again once you got the access."} />
      }
    </>
  )
}
