import React from "react"
import errorImage from "../../assets/img/404.png"
import { Link } from "react-router-dom"

export default function Error({ Status, Message }) {
    return (
        <div className="container">
            <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
                <h1 className="mb-3">{Status ? Status : "404"}</h1>
                <h5>{Message ? Message : 'The page you are looking for doesn"t exist."'}</h5>
                {Status !== 403 && <Link className="btn" to="/">Back to home</Link>}
                <img src={errorImage} className="img-fluid py-5" alt="unauthorized" />
            </section>
        </div>

    )
}
